<template>
	<div
		id="quick-assessment"
		:class="{ darkBackground: currentStep.background }"
	>
		<div
			v-for="index in 2"
			:id="`slide-${index - 1}`"
			:class="`${currentSlide + 1 == index ? 'slide-in' : 'slide-out'} ${
				slideNode[index - 1].stepClasslist
			}`"
			:key="index"
		>
			<div class="slide-content">
				<div class="back-caret" onclick="window.history.back()"></div>

				<div
					v-if="slideNode[index - 1].stepIconClass"
					class="stepIcon"
					:class="slideNode[index - 1].stepIconClass"
				></div>
				<h3
					v-if="slideNode[index - 1].headline"
					v-html="slideNode[index - 1].headline"
				></h3>
				<p class="body" v-html="slideNode[index - 1].body"></p>
				<div class="button-container">
					<a
						v-for="(theButton, buttonIndex) in slideNode[index - 1]
							.buttons"
						:class="theButton.buttonClasslist"
						:key="buttonIndex"
						@click="buttonClick(theButton)"
					>
						<div
							v-if="theButton.buttonIconClass"
							class="button-icon"
							:class="theButton.buttonIconClass"
						></div>
						<p
							class="button-text"
							v-html="theButton.buttonText"
						></p>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import QuickAssessmentSteps from "@/data/QuickAssessmentSteps.js";

export default {
	name: "TheQuickAssessment",

	data() {
		return {
			global: this.$root.$children[0].$data, // App contains our variables; it's the first child of $root
			currentSlide: 0,
			slideNode: [
				QuickAssessmentSteps["start"],
				QuickAssessmentSteps["start"],
			],
			currentStep: QuickAssessmentSteps["start"],
		};
	},

	methods: {
		buttonClick(theButton) {
			// console.group(
			// 	"%cB u t t o n  C l i c k",
			// 	"padding: 8px; background-color: #AAA; color: white; border-radius: 4px; text-transform: uppercase; font-family: Helvetica, sans-serif; font-size: 10px;"
			// );
			// this.consoleLogParameter("Button", theButton.buttonText);
			// this.consoleLogParameter("Step", location.hash);
			// this.consoleLogParameter(
			// 	"Destination",
			// 	theButton.clickActionDestination
			// );
			// this.consoleLogParameter("Analytics Handle", theButton.analyticsID);

			// console.groupEnd();

			if (theButton.analyticsID) window.s.tl(true, 'o',theButton.analyticsID);

			if (theButton.clickActionDestination) {
				if (theButton.clickActionDestination.includes("#"))
					location.hash = theButton.clickActionDestination;
					
				else location.href = theButton.clickActionDestination + ((theButton.clickActionDestination.indexOf('?') > -1) ? '&' : '?') + window.incomingQueryParams;
			}
		},

		consoleLogParameter(title, value) {
			const ss =
				"font-family: Helvetica, sans-serif; font-size: 12px; color: #AAA; padding: 6px 0 6px 0;";
			value = value == undefined || value == "" ? "not specified" : value;
			value = value.replace(/(<([^>]+)>)/gi, "");
			const vss =
				ss + "color: " + (value == "not specified" ? "red;" : "black;");
			console.log("%c%s %c%s", ss, title, vss, value);
		},

		handleHashChange() {

			if (QuickAssessmentSteps[location.hash.substr(1)] == undefined) return;

			var slide = document.getElementById(`slide-${this.currentSlide}`);
			slide.classList.remove("slide-in");
			slide.classList.add("slide-out");

			if (QuickAssessmentSteps[location.hash.substr(1)]) {
				this.currentStep =
					QuickAssessmentSteps[location.hash.substr(1)];
				this.currentSlide = (this.currentSlide + 1) % 2;
				this.$set(this.slideNode, this.currentSlide, this.currentStep);
				slide = document.getElementById(`slide-${this.currentSlide}`);
				slide.classList.remove("slide-out");
				slide.classList.add("slide-in");
			}
		},
	},

	activated() {
		// console.log(QuickAssessmentSteps);
		window.addEventListener("hashchange", this.handleHashChange, false);
		if (location.hash == "") location.hash = "#start";
		this.handleHashChange();
	},

	deactivated() {
		window.removeEventListener("hashchange", this.handleHashChange, false);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#quick-assessment,
#quick-assessment:after {
	position: absolute;
	left: 50%;
	top: 0;
	bottom: 0;
	width: 100vw;
	transform: translateX(-50%);
	z-index: 2;
	overflow: hidden;
}

#quick-assessment:after {
	content: "";
	z-index: -1;
	opacity: 0;
	transition: opacity 1s;
}

#quick-assessment.darkBackground::after {
	background-image: radial-gradient(circle at 50% 0, #317298, #101f30 102%);
	opacity: 1;
	width: 100vw;
	left: 50%;
	transform: translateX(-50%);
}

[id^="slide-"] {
	position: absolute;
	left: 0;
	width: 100%;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	animation-fill-mode: forwards !important;
	display: flex;
	opacity: 0;
}

.slide-content {
	max-width: 400px;
	margin: 0 auto;
	padding: 0 15px;
	box-sizing: border-box;
}

.button-container {
	display: flex;
	margin: 36px auto;
}

.button-container a {
	cursor: pointer;
	user-select: none;
	box-sizing: border-box;
}

.stepIconButtons .button-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.stepIconButtons .button-container a {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 15px;
	height: 99px;
	width: 130px;
	text-decoration: none;
	background-color: #ffffff;
	border-radius: 16px;
	border: solid 1px #cccccc;
	box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
	transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out,
		transform 0.25s cubic-bezier(0.17, 0.88, 0.42, 2.1);
}

.stepIconButtons .button-container a:hover {
	transform: scale(1.05);
	transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out,
		transform 0.25s cubic-bezier(0.17, 0.88, 0.42, 2.1);
	background-color: #f2fcf8;
	border: solid 1px #00b29f;
}

.stepIconButtons .button-container a .button-text {
	font-family: "Lato", sans-serif;
	font-size: 14px;
	font-weight: 700;
	text-align: center;
	color: #101f30 !important;
	margin: 8px 0 0;
	padding: 0 5px;
	line-height: 1.1;
	max-width: 100%;
}

.button-icon {
	display: none;
}

.stepIconButtons .button-icon {
	flex: 0 0 auto;
	background-position: center center;
	display: block;
	width: 40px;
	height: 40px;
	background-size: contain;
	background-repeat: no-repeat;
	margin: 12px 0 0 0;
}

p {
	font-family: Lato, sans-serif;
	font-size: 27px;
	line-height: 1.5;
	color: white;
	text-align: center;
}

h3 {
	text-align: center;
	color: #08c177 !important;
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 12px auto;
}

.stepIcon {
	width: 100px;
	height: 100px;
	background-size: 90%;
	background-position: center center;
	background-repeat: no-repeat;
	margin: 0 auto 48px auto;
}

.endStep .button-container {
	margin: 0 auto;
	display: block;
}

.endStep a.readMore {
	cursor: pointer;
	display: block;
	margin-top: 4px;
}

.endStep a.readMore p {
	color: #00aeef !important;
	transition: inherit;
	font-family: Lato, sans-serif;
	font-size: inherit;
}

a.lt-button {
	display: block;
	border-radius: 4px;
	background-color: #00aeef;
	width: 100%;
	height: 50px;
	margin: 36px 0;
}

a.lt-button p.button-text {
	text-align: center !important;
	color: white;
	font-family: Lato, sans-serif;
	font-size: 18px;
	font-weight: bold;
	line-height: 50px;
}

.endStep h3,
.endStep p {
	font-family: Lato, sans-serif;
	font-size: 17px;
	line-height: 1.4;
	color: white !important;
	letter-spacing: 0;
	text-transform: none;
	margin: 0;
	text-align: left !important;
}

.endStep h3 {
	font-weight: bold;
	margin-bottom: 12px;
	font-size: 18px;
}

.step-credit-score p {
	text-align: center;
}

.step-credit-score .button-container {
	width: 100%;
	max-width: 400px;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
}

.step-credit-score .button-container > a {
	box-sizing: border-box;
	margin: 12px 0 !important;
	width: 100%;
	height: 50px;
	font-size: 17px;
	line-height: 50px;
	padding: 0 18px;
	border: 1px solid #aaa;
	border-radius: 5px;
	margin: 18px auto;
	background-color: #f2fcf8;
	position: relative;
	transition: background-color 0.5s, transform 0.5s;
}

.step-credit-score .button-container > a:after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: opacity 0.5s;
}

.step-credit-score .button-container > a:hover {
	transform: scale(1.05);
	background-color: white;
}

.step-credit-score .button-container > a:hover:after {
	opacity: 1;
}

.step-credit-score .button-container > a .button-text {
	line-height: 50px;
	display: flex;
	justify-content: space-between;
	font-size: 17px;
	color: #101f30;
}

.step-credit-score .button-text * {
	font-weight: bold !important;
}

.green-border {
	border-color: #08c177 !important;
}

.blue-border {
	border-color: #00aeef !important;
}

.red-border {
	border-color: #ff704d !important;
}

.step-start {
	top: 40%;
}

.step-start .back-caret {
	display: none;
}

.step-start p.body {
	text-align: center;
	margin: 0;
	font-size: 17px;
	line-height: 1.5;
	color: #454545;
}

.step-start .button-container {
	margin: 0 auto;
}

.slide-in {
	animation: slide-in-animation 0.5s ease-in-out;
}

.slide-out {
	animation: slide-out-animation 0.5s ease-in-out;
	pointer-events: none;
}

.back-caret {
	position: absolute;
	left: 50%;
	top: 18px;
	width: 100%;
	max-width: 400px;
	height: 18px;
	background-size: auto 100%;
	background-repeat: no-repeat;
	background-position: 18px center;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOC44MDUgMjcuNzYiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOm5vbmU7c3Ryb2tlOiMzNWI3NzY7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7c3Ryb2tlLXdpZHRoOjNweDt9PC9zdHlsZT48L2RlZnM+PHBvbHlsaW5lIGNsYXNzPSJhIiBwb2ludHM9IjE3LjMwNSAxLjUgMi4zNTIgMTMuODggMTcuMzA1IDI2LjI2Ii8+PC9zdmc+);
	transform: translate(-50%);
	cursor: pointer;
}

@keyframes slide-in-animation {
	0% {
		opacity: 0;
		transform: translateY(-10%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
		pointer-events: all;
	}
}

@keyframes slide-out-animation {
	0% {
		opacity: 1;
		transform: translateY(0%);
	}
	100% {
		opacity: 0;
		transform: translateY(-10%);
		pointer-events: none;
	}
}

@media (max-width: 481px) {
	#quick-assessment.darkBackground::after {
		background-image: radial-gradient(
			circle at 50% 0,
			#101f30,
			#101f30 102%
		);
	}
	.stepIcon {
		margin-bottom: 32px;
	}
}

@media (max-width: 350px) {
	.stepIconButtons .button-container a {
		margin: 12px 6px;
	}
}
</style>
