<template>
	<div class="worksheet-grid" v-bind:id="'worksheet-' + worksheet.slug">
		<div v-for="(item, index) in worksheet.inputs" :key="index">
			<label v-if="item.default" v-bind:for="worksheet.slug + index"
				> {{  item.name  }}</label
			>
			<div v-else class="fw di">
				<input
					type="text"
					v-bind:placeholder="worksheet.additionalItemPlaceholder"
					v-model="item.name"
				/>
			</div>
			<div class="fw df">
				<input
					type="number"
					inputmode="numeric"
					pattern="\d*"
					v-bind:id="worksheet.slug + index"
					@keyup="amount_entered(index, $event.target)"
					@blur="round_value($event.target)"
				/>
			</div>
			<div
				v-if="!item.default"
				class="cancel-icon"
				@click="delete_row(index)"
			></div>
		</div>
		<div v-if="typeof worksheet.additionalButton != 'undefined'">
			<div class="di">
				<button class="text-button" @click="add_row(worksheet)">
					<div class="plus-icon"></div>
					{{ worksheet.additionalButton }}
				</button>
			</div>
			<div></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "WorksheetSum",
	data: function () {
		return {};
	},
	props: ["worksheet"],
	methods: {
		amount_entered: function (theIndex, target) {
			const theValue = Math.round(target.value);
			this.worksheet.inputs[theIndex].amount = theValue;
			this.calc_sum();
		},

		calc_sum: function () {
			const limit = this.worksheet.inputs.length;
			var sum = 0;
			for (let i = 0; i < limit; i++) {
				sum += this.worksheet.inputs[i].amount;
			}
			this.worksheet.sum = sum;
		},

		round_value: function (target) {
			target.value = Math.round(target.value);
		},

		add_row: function (theWorksheet) {
			theWorksheet.inputs.push({ name: "", amount: 0, default: false });
		},

		delete_row: function (theIndex) {
			this.$delete(this.worksheet.inputs, theIndex);
			this.calc_sum();
		},

		get_value: function (theIndex) {
			return this.worksheet.inputs[theIndex].amount;
		},
	},
};
</script>
