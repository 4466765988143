<template>
	<div id="solutions-banner" v-if="global.solutionGroup">
		<div class="banner-wrap">
			<div id="banner-icon"></div>
			<div class="banner-dynamic">
				<p v-if="global.solutionsBannerReviewText">
					<b>Quick Review</b>: You have <span id="sbReview">{{ global.solutionsBannerReviewText }}</span>
				</p>
				<p>
					<span id="notRecommendedPrefix" v-if="!global.recommendedSolutions[global.solutionGroup].includes(this.$route.path.substr(1))"
						>This chapter is not recommended for you. <br></span
					><b>Recommended Solutions: </b>

					<span v-if="global.solutionGroup == 1">
						<router-link to="/debt-consolidation"
							>Debt Consolidation</router-link
						>.
					</span>

					<span v-if="global.solutionGroup == 2">
						<router-link to="/balance-transfer-cards"
							>Balance Transfer Cards</router-link
						>,
						<router-link to="/debt-consolidation"
							>Debt Consolidation</router-link
						>, and
						<router-link to="/take-control-of-your-debt"
							>Take Control of Your Debt</router-link
						>.
					</span>

					<span v-if="global.solutionGroup == 3">
						<router-link to="/take-control-of-your-debt"
							>Take Control of Your Debt</router-link
						>.
					</span>

					<span v-if="global.solutionGroup == 4">
						<router-link to="/negotiating-debt"
							>Negotiating Debt</router-link
						>,
						<router-link to="/take-control-of-your-debt"
							>Take Control of Your Debt</router-link
						>
						and
						<router-link to="/bankruptcy">Bankruptcy</router-link>.
					</span>

					<span v-if="global.solutionGroup == 6">
						<router-link to="/expenses-troubleshooter"
							>Expenses Troubleshooter</router-link
						> and
						<router-link to="/take-control-of-your-debt"
							>Take Control of Your Debt</router-link
						>.
					</span>

					<span v-if="global.solutionGroup == 9">
						<router-link to="/negotiating-debt"
							>Negotiating Debt</router-link
						>
						and
						<router-link to="/bankruptcy">Bankruptcy</router-link>.
					</span>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "TheSolutionsBanner",
	data() {
		return {
					global: this.$root.$children[0].$data, // App contains our variables; it's the first child of $root
			// Globals:
			// solutionsBannerReviewText: "",
			// solutionGroup: 0,
			// recommendedSolutions: []

		}

	}
};
</script>

<style>
#solutions-banner {
	position: relative;
	top: 0;
	left: 50%;
	height: auto;
	width: 100vw;
	min-height: 70px;
	background: #f5f5f5;
	border: dotted 1px #e1e1e1;
	z-index: 999;
	/* box-shadow: 0 0 8px #AAA; */
	transform: translateX(-50%);
	display: block;
}

[data-currentpage = ""] #solutions-banner,
[data-currentpage = "debt-assessment"] #solutions-banner {
	display: none;
}

/* #solutions-banner.sticky {
	position: fixed;
} */

#banner-icon {
	width: 40px;
	height: 40px;
	flex: 0 0 45px;
	background-repeat: no-repeat;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMC43IDQwIiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxwYXRoIGQ9Ik0yMy42IDM2LjNWMzFjMC0uNS40LS45LjktLjloMy42bC00LjUgNi4yek0yLjkgMzguMmgwYy0uNSAwLS45LS40LS45LS45TDEuNyA3LjFjMC0uNS40LS45LjktLjloMi45QTIuNTggMi41OCAwIDAgMCA4IDguMWEyLjU4IDIuNTggMCAwIDAgMi41LTEuOWw5LjItLjFjLjIgMS4zIDEuMyAyLjMgMi42IDIuM3MyLjQtMSAyLjYtMi4zaDIuOGMuNSAwIC45LjQuOS45bC4yIDIxLjRoLTQuNGMtMS41IDAtMi42IDEuMi0yLjYgMi43bC4xIDcuMWgtMTl6TTcuMSAyLjdjMC0uNS40LS45LjktLjlzLjkuNC45Ljl2Mi43YzAgLjUtLjQuOS0uOS45cy0uOS0uNC0uOS0uOVYyLjd6bTE0LjQuMmMwLS41LjQtLjkuOS0uOXMuOS40LjkuOXYyLjdjMCAuNS0uNC45LS45LjlzLS45LS40LS45LS45VjIuOXptOS4xIDI2LjV2LS4zbC0uMS0yMi4yYzAtMS41LTEuMi0yLjYtMi42LTIuNmgwLTIuN1YyLjlhMi42OSAyLjY5IDAgMSAwLTUuNCAwdjEuNGwtOS4xLjFWMi43QTIuNjkgMi42OSAwIDAgMCA4IDBDNi42IDAgNS40IDEuMiA1LjQgMi43djEuN0gyLjZDMS4yIDQuNCAwIDUuNiAwIDcuMWwuMyAzMC4zYzAgMS41IDEuMiAyLjYgMi42IDIuNmgwbDIwLjMtLjJzLjEgMCAuMS0uMWgwbC4xLS4xIDctOS44Yy4yLS4yLjItLjMuMi0uNHpNMTguMSAxNS4yaDcuN2MuNSAwIC45LS40LjktLjlzLS40LS45LS45LS45aC03LjdjLS41IDAtLjkuNC0uOS45cy40LjkuOS45bTguNSA1LjZjMC0uNS0uNC0uOS0uOS0uOUgxOGMtLjUgMC0uOS40LS45LjlzLjQuOS45LjloNy43Yy41IDAgLjktLjQuOS0uOW0tMTQuNS03LjdsLTQuNiA0LjQtMS44LTEuOGMtLjMtLjMtLjktLjMtMS4yIDBzLS4zLjkgMCAxLjJsMi40IDIuNGMuMi4yLjQuMy42LjNzLjQtLjEuNi0uMmw1LjItNWEuNzUuNzUgMCAwIDAgMC0xLjJjLS4zLS41LS44LS41LTEuMi0uMW0wIDYuNEw3LjUgMjRsLTEuOC0xLjhjLS4zLS4zLS45LS4zLTEuMiAwcy0uMy45IDAgMS4ybDIuNCAyLjRjLjIuMi40LjMuNi4zcy40LS4xLjYtLjJsNS4yLTVhLjc1Ljc1IDAgMCAwIDAtMS4yYy0uMy0uNS0uOC0uNS0xLjItLjJtMCA2LjVsLTQuNiA0LjQtMS44LTEuOGMtLjMtLjMtLjktLjMtMS4yIDBzLS4zLjkgMCAxLjJsMi40IDIuNGMuMi4yLjQuMy42LjNzLjQtLjEuNi0uMmw1LjItNWEuNzUuNzUgMCAwIDAgMC0xLjJjLS4zLS40LS44LS40LTEuMi0uMSIgZmlsbD0iIzAwYjI5ZiIvPjwvc3ZnPg==");
}
/* #solutions-banner.stickyBanner {
	position: fixed;
	top: 0;
} */

#solutions-banner .banner-wrap {
	max-width: 748px;
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	padding: 14px 10px 15px 10px;
	align-items: flex-start;
	font-size: 14px;
	line-height: 1.5;
}

#sbReview {
	text-transform: lowercase;
}

/* Solutions: Are you on the page of one that is not recommended */

#notRecommendedPrefix {
	display: inline;
}

/* take-control-of-your-debt is recommended for solutions 1, 2, 3, 4 */

body[data-currentpage="take-control-of-your-debt"]
	body[data-solution="1"]
	#notRecommendedPrefix,
body[data-currentpage="take-control-of-your-debt"]
	body[data-solution="2"]
	#notRecommendedPrefix,
body
	[data-currentpage="take-control-of-your-debt"]
	body[data-solution="3"]
	#notRecommendedPrefix,
body[data-currentpage="take-control-of-your-debt"]
	body[data-solution="4"]
	#notRecommendedPrefix {
	display: none;
}
/* personal-loans is recommended for solutions 1, 2  */

body[data-currentpage="personal-loan"]
	body[data-solution="1"]
	#notRecommendedPrefix,
body[data-currentpage="personal-loan"]
	body[data-solution="2"]
	#notRecommendedPrefix {
	display: none;
}
/* balance-transfer is recommended for solutions 2  */

body[data-currentpage="balance-transfer"]
	body[data-solution="2"]
	#notRecommendedPrefix {
	display: none;
}
/*  negotiating-debt and bankruptcy are recommended for solutions 4, 9 */

body[data-currentpage="negotiating-debt"]
	body[data-solution="4"]
	#notRecommendedPrefix,
body[data-currentpage="bankruptcy"]
	body[data-solution="4"]
	#notRecommendedPrefix,
body[data-currentpage="negotiating-debt"]
	body[data-solution="9"]
	#notRecommendedPrefix,
body[data-currentpage="bankruptcy"]
	body[data-solution="9"]
	#notRecommendedPrefix {
	display: none;
}
/* Expenses troubleshooter is never not recommended */

body[data-currentpage="expenses-troubleshooter"] #notRecommendedPrefix {
	display: none;
}
</style>