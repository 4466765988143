<template>
	<main>
		<section id="intro">
			<the-quick-assessment></the-quick-assessment>
			<div class="headlines">
				<div>
					<p class="smallcaps">A Complete Guide</p>
					<h1>Becoming Debt&nbsp;Free</h1>
				</div>
			</div>
		</section>

		<section id="toc">
			<h3>Introduction</h3>
			<h4>Guide Overview</h4>
			<p>
				Debt can be complex, but yours doesn’t have to be. The steps to
				becoming debt free are proven, and this guide can help you
				figure out which steps are right for you.
			</p>
			<div class="toc-section chapter-icon-take-control-of-your-debt">
				<h3>Section One</h3>
				<h4>Take Control of Your Debt</h4>
				<p>
					Understand how credit and debt work together and how you can
					craft a plan that works to improve your finances fast.
				</p>
				<router-link to="/take-control-of-your-debt"
					>Read More...</router-link
				>
			</div>
			<div class="toc-section chapter-icon-debt-consolidation">
				<h3>Section Two</h3>
				<h4>Debt Consolidation</h4>
				<p>
					If you have good credit, it’s possible to consolidate high
					interest debt like credit cards and often repay it years
					faster.
				</p>
				<router-link
					id="toc-debt-consolidation"
					to="/debt-consolidation"
					>Read More...</router-link
				>
			</div>
			<div class="toc-section chapter-icon-balance-transfer-cards">
				<h3>Section Three</h3>
				<h4>Balance Transfer Cards</h4>
				<p>
					For very good or excellent credit, 0% APR balance transfer
					credit cards are one of the best options to pay down debt
					quickly.
				</p>
				<router-link
					id="toc-balance-transfer-cards"
					to="/balance-transfer-cards"
					>Read More...</router-link
				>
			</div>
			<div class="toc-section chapter-icon-the-power-of-home-equity">
				<h3>Section Four</h3>
				<h4>The Power of Home Equity</h4>
				<p>
					If you own a home, you probably have earned equity. You may
					be able to leverage it to get out of high interest debt for
					good.
				</p>
				<router-link
					id="toc-the-power-of-home-equity"
					to="/the-power-of-home-equity"
					>Read More...</router-link
				>
			</div>
			<div class="toc-section chapter-icon-negotiating-debt">
				<h3>Section Five</h3>
				<h4>The Art of Negotiating Debt</h4>
				<p>
					If you have poor credit and payment is a tall order, learn
					how to negotiate with creditors for a plan you can manage.
				</p>
				<router-link id="toc-negotiating-debt" to="/negotiating-debt"
					>Read More...</router-link
				>
			</div>
			<div class="toc-section chapter-icon-bankruptcy">
				<h3>Section Six</h3>
				<h4>Bankruptcy</h4>
				<p>
					If you’ve ruled out all other options, two types of
					bankruptcy law can offer relief. Learn which might apply to
					you.
				</p>
				<router-link id="nav-bankruptcy" to="/bankruptcy"
					>Read More...</router-link
				>
			</div>
			<div class="toc-section chapter-icon-debt-assessment">
				<h3 class="lt-electric-salmon">Appendix A</h3>
				<h4>Debt Assessment Tool</h4>
				<p>
					Not sure what you need to change to eliminate debt? This
					FREE interactive tool can help you answer that in minutes.
				</p>
				<router-link id="toc-debt-assessment" to="/debt-assessment"
					>Take Assessment</router-link
				>
			</div>
			<div class="toc-section chapter-icon-expenses-troubleshooter">
				<h3 class="lt-electric-salmon">Appendix B</h3>
				<h4>Expenses Troubleshooter</h4>
				<p>
					This will help you potentially identify places where you can
					free up your spending cash to pay down debt faster.
				</p>
				<router-link
					id="toc-expenses-troubleshooter"
					to="/expenses-troubleshooter"
					>Open Troubleshooter</router-link
				>
			</div>
		</section>
	</main>
</template>

<script>
import TheQuickAssessment from "@/components/TheQuickAssessment";

export default {
	name: "Overview",
	components: {
		TheQuickAssessment
	},
};
</script>

<style scoped>
#intro {
	box-sizing: border-box;
	height: calc(100vh - 110px);
	min-height: 680px;
	max-height: 750px;
	padding: 0 15px 0 15px;
}

#intro::before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	top: -30px;
	height: calc(40% + 18px);
	background-image: url(/assets/images/debt-microsite-anchor.png);
	background-position: center top;
	background-repeat: no-repeat;
	background-size: contain;
}

.headlines {
	position: absolute;
	display: flex;
	justify-content: center;
	width: 100%;
	top: 40%;
	bottom: 0;
	left: 0;
}

#intro:after {
  background-image: radial-gradient(circle at 50% 0, #D8E8E7, #F2FCF8 53%);
}

#intro h1 {
	font-size: 48px;
	line-height: 1.1;
	letter-spacing: -1px;
	margin: 0;
}

#intro .smallcaps {
	font-size: 18px;
	font-weight: bold;
	color: #101f30;
	margin: 18px 0 4px 0;
	text-align: center;
}

.lt-electric-salmon {
	color: #ff7b4f !important;
}

#toc:after {
	background-color: white;
}

#toc h3 {
	font-size: 15px;
	font-weight: bold;
	line-height: 1.5;
	letter-spacing: 0.75px;
	color: #08c177;
	text-transform: uppercase;
	margin: 0;
}

#toc > h3 {
	text-align: center;
}

#toc h4 {
	font-size: 22px;
	font-weight: normal;
	letter-spacing: normal;
	color: #003965;
	margin: 4px 0 8px 0;
}

#toc > h4 {
	text-align: center;
	margin: 4px auto 24px auto;
	font-family: Georgia, serif;
	font-size: 27px;
}

#toc > p {
	margin: 24px 0 36px 0;
}

#toc a {
	display: block;
	margin-top: 4px;
	font-size: 17px;
	color: #00aeef;
}

#toc .toc-section {
	border-top: solid 1px #e1e1e1;
	padding: 14px 14px 14px 190px;
	background-size: 120px 120px;
	background-position: 34px 34px;
	background-repeat: no-repeat;
}

#toc div.toc-section:last-of-type {
	border-bottom: solid 1px #e1e1e1;
}

@media (max-width: 481px) {

	#intro {
		max-height: 550px;
		min-height: unset;
	}

	#intro::before {
		background-position: calc(100% + 30px) top;
	}

	#intro .smallcaps {
		text-align: left;
		font-size: 14px;
	}

	#intro h1 {
		text-align: left;
		font-size: 36px;
	}

	#toc .toc-section {
		background-size: 0;
		padding-left: 14px;
	}

	.headlines {
	top: calc(40% - 36px);
}

}

@media (max-width: 360px) {
	#intro h1 {
		font-size: 30px;
	}
}
</style>
