import Vue from 'vue'
import VueRouter from 'vue-router'
import Overview from '@/views/Overview.vue'
import TakeControlOfYourDebt from '@/views/TakeControlOfYourDebt.vue'
import BalanceTransferCards from '@/views/BalanceTransferCards.vue'
import DebtConsolidation from '@/views/DebtConsolidation.vue'
import NegotiatingDebt from '@/views/NegotiatingDebt.vue'
import Bankruptcy from '@/views/Bankruptcy.vue'
import ExpensesTroubleshooter from '@/views/ExpensesTroubleshooter.vue'
import DebtAssessment from '@/views/DebtAssessment.vue'
import ThePowerOfHomeEquity from '@/views/ThePowerOfHomeEquity.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/overview',
		hash: '#start',
		name: 'Overview',
		component: Overview
	},
	{
		path: '/balance-transfer-cards',
		name: 'Balance Transfer Cards',
		component: BalanceTransferCards
	},
	{
		path: '/take-control-of-your-debt',
		name: 'Take Control of Your Debt',
		component: TakeControlOfYourDebt
	},
	{
		path: '/debt-consolidation',
		name: 'Debt Consolidation',
		component: DebtConsolidation
	},
	{
		path: '/negotiating-debt',
		name: 'Negotiating Debt',
		component: NegotiatingDebt
	},
	{
		path: '/bankruptcy',
		name: 'Bankruptcy',
		component: Bankruptcy
	},
	{
		path: '/debt-assessment',
		name: 'Debt Assessment',
		component: DebtAssessment
	},
	{
		path: '/expenses-troubleshooter',
		name: 'Expenses Troubleshooter',
		component: ExpensesTroubleshooter
	},
	{
		path: '/the-power-of-home-equity',
		name: 'The Power of Home Equity',
		component: ThePowerOfHomeEquity
	},
	{
		path: '*',
		redirect: '/overview'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}	
})

router.afterEach((to, from) => {
	// console.log("From: ", from.name);
	// console.log("To: ", to.name)
	if (from.name == null) {
		// will be true for initial visit
		if (to.name != null)
		{
			window.ltanalytics && window.ltanalytics.page(
				{
					'pageName': 'debteliminationguide/' + to.name,
					'page_intended_product': 'debt'
				});
			// console.log("page call: debteliminationguide/" + to.name)
		}
		return;
	} else if (to.name.split('#')[0] != from.name.split('#')[0]) {
			// Remove the hashes. If these pages are the same, save for the hash, do not send analytics a page event

		document.body.dataset.currentpage = to.path.substr(1);
		window.ltanalytics && window.ltanalytics.page(
			{
				'pageName': 'debteliminationguide/' + to.name,
				'page_intended_product': 'debt'
			})
		// console.log("page call: debteliminationguide/" + to.name)

	} 
	// else 	console.log ("No page call. (" +to.name + ")");


})

export default router
