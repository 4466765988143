<template>
	<main>
		<section class="backdrop white-section">
			<div class="chapter-header-icon chapter-icon-debt-assessment"></div>
			<p class="eyebrow">Better understand your debt</p>
			<h1>Your Free Debt Assessment</h1>
			<p>
				If you’ve made it here, you’re one step closer to understanding
				your financial situation than ever before with this interactive
				tool. <br /><br />We’ll help you learn where you spend your
				money and how much. Then we’ll take a look at your debt itself
				in terms that banks use to determine financial wellness so that
				it’ll become much clearer what your debt reduction goals should
				look like. Finally, we’ll use your credit score to come up with
				a recommendation to set your Becoming Debt Free plan in action.
			</p>
		</section>

		<section class="with-shelf sectionclosed" id="part-one">
			<div
				@click="toggleSection('#part-one')"
				id="part-one-btn"
				class="section-shelf show-hide-btn"
			>
				<div class="circle-arrow up xpander"></div>
				<p>
					<span class="lt-green"><b>Section One: </b></span>Your
					Spending
				</p>
			</div>
			<div class="show-hide-content">
				<h1>Spending Wizard</h1>
				<h2 class="wizard-subhead">
					“Do I spend more than I earn each month?”
				</h2>
				<p>
					A lot of people don’t know the answer to this question. Why?
					Because there’s a big difference between how much you spend
					each month and how much you pay in bills. Every month, your
					paycheck goes to two primary categories: fixed expenses and
					unfixed ones. A fixed expense is a predictably recurring
					bill like your rent, utility bill, TV streaming, student
					loans and minimum credit card payments. Unfixed are expenses
					like grocery shopping, gas and online shopping.
					<br />
					<br />
					Obviously, loans and credit cards help spread out larger
					purchases into manageable bites, but it’s often difficult to
					picture how one big credit card bill really impacts your
					financial life. The illustration below demonstrates how
					credit cards are fixed expenses that go up over time. Worse,
					these often include interest payments, which can also make
					it difficult to eliminate the debt. As long as these
					payments stick around, they’re essentially a type of pay
					cut.
				</p>
				<div class="illustration-sneaking-spending-growth"></div>
				<p>
					Getting a handle on how much you truly spend versus how much
					you earn is paramount to taking control of your debt. To
					figure out how much you spend each month, start with what
					you know. Your bank and credit card statements are your
					friend while figuring out your spending. Downloading a
					budgeting app can also help keep your typical spending under
					control and even track how your spending is shaping up based
					on monthly targets you set.
				</p>
				<article class="inset-panel">
					<a
						class="hollow-green-button absolute-right downloadCSV"
						@click="downloadCSV"
						v-if="notMsie"
						download="financial_data.csv"
						>Download Spreadsheet</a
					>
					<div class="eyebrow">Interactive Wizard</div>
					<h1>Your Spending Budget</h1>
					<p>
						This worksheet will help determine everything you spend
						money on, so you can make meaningful decisions about
						your debt and maximize the potential benefits of this
						guide.
					</p>
					<div
						class="calc-toggle-sec sectionclosed"
						id="part-one-calc"
					>
						<div
							@click="toggleSection('#part-one-calc')"
							id="show-hide-calc-btn"
							class="show-hide-btn show-hide-calc-btn"
						>
							<div class="circle-arrow up xpander"></div>
							<p>Select to Start Spending Budget</p>
						</div>
						<div class="show-hide-content calc-content">
							<h2>Step 1: Monthly Take-Home Pay</h2>
							<worksheet-sum
								v-bind:worksheet="IncomeWorksheet"
							></worksheet-sum>
							<hr class="dotted" />
							<h2>Step 2: Monthly Non-Debt Fixed Payments</h2>
							<p>
								These are recurring expenses that happen every
								month like clockwork but aren’t debt.
								<b
									>We’ll handle debt and credit card payments
									separately. Leave fields that don’t apply
									blank.</b
								>
							</p>
							<worksheet-sum
								v-bind:worksheet="MonthlyExpensesWorksheet"
							></worksheet-sum>
							<hr class="dotted" />
							<h2 id="step-three">
								Step 3: Monthly Fixed Debt Payments
							</h2>
							<p>
								These are all recurring debt payments
								<b
									>that appear on your credit report except
									credit cards and personal loans (<a
										@click="gotoAnchor('step-four', $event)"
										>see below</a
									>). Leave fields that don’t apply blank.</b
								>
							</p>
							<worksheet-sum
								v-bind:worksheet="FixedDebtWorksheet"
							></worksheet-sum>
							<div class="inset-summary">
								<p>Usable Income</p>
								<p class="lt-green">
									{{ usableIncome | currency }}
								</p>
								<p class="subtext">
									Income minus fixed expenses
								</p>
							</div>
							<h2 id="step-four">
								Step 4: Credit Card Monthly
								<b>Minimum Payments</b>
							</h2>
							<worksheet-sum
								v-bind:worksheet="CreditCardWorksheet"
							></worksheet-sum>
							<hr class="dotted" />
							<h2>Step 5: Monthly Variable Expenses</h2>
							<p>
								These expenses can change month to month.
								Obviously, these are the hardest to estimate.
								Try calculating the monthly average of what
								you’ve spent or likely will spend in a year,
								including vacations and holiday expenses.
								Looking at a few credit card or bank statements
								can help a lot.
							</p>
							<worksheet-sum
								v-bind:worksheet="MonthlyVariableExpenses"
							></worksheet-sum>
							<div class="inset-summary">
								<p>Disposable income</p>
								<p class="lt-green">
									{{ disposableIncome | currency }}
								</p>
								<p class="subtext">Income minus all expenses</p>
							</div>
							<!-- <a class="hollow-green-button absolute-right downloadCSV" @click="downloadCSV" v-if="notMsie" download="financial_data.csv">Download Spreadsheet</a> -->
						</div>
					</div>
				</article>
				<p>
					Disposable income is the money you have left over after you
					cover necessary living expenses, such as food, shelter,
					transportation and healthcare. As obvious as that may seem,
					how much of it you actually have may not be so clear.
					Ultimately, it’s vital to know — or, at least, guesstimate —
					that amount, because you can use it to pay off your debt
					faster and make informed financial decisions. This worksheet
					should give you a good idea where you land. <br />
					<br />
					If you have little to no disposable income, you have two
					primary options. <br />
					<br />
					The first option is to increase your income. You don’t
					necessarily need a different job, but side gigs can help,
					such as ride hailing or picking up food service or grocery
					shifts. Your life situation may limit this option, but if
					you’re able, it can be a good way to get you back on track
					and add some financial stability to your life.
					<br />
					<br />
					Another option is to cut back on spending where possible. If
					you know there are areas in which you splurge, you may want
					to dial them back for a while so you can move ahead. Here
					are some common techniques to reduce spending easily:
				</p>
				<ul class="green-bullets">
					<li>
						Audit your subscriptions and determine if you can pare
						back on magazines, music services and streaming
						accounts, such as Netflix. Look into splitting the cost
						with friends or find free alternatives. If you can’t,
						cancel them — this is cash that can go straight to
						eliminating debt.
					</li>
					<li>
						Instead of grocery shopping when you’re hungry, plan
						your meals ahead of time. That way, you’ll buy
						ingredients you can use again and again so less goes to
						waste. Use leftovers or meal prep for lunch instead of
						dining out.
					</li>
					<li>
						Make use of loyalty cards and coupons. Sign up for
						rewards programs at stores you visit frequently and
						check out coupon apps, such as SavingStar.
					</li>
				</ul>
				<p>
					One of the most <b>important considerations</b> when making
					adjustments to your finances is figuring out how to make the
					minimum payments at least. If you don’t have or can’t earn
					enough cash to make that happen, it will be difficult to
					find a repayment strategy that doesn’t involve some form of
					debt forgiveness, which can impact your credit score
					negatively.
				</p>
			</div>
		</section>
		<section class="with-shelf sectionclosed" id="part-two">
			<div
				@click="toggleSection('#part-two')"
				id="part-two-btn"
				class="section-shelf show-hide-btn"
			>
				<div class="circle-arrow up xpander"></div>
				<p>
					<span class="lt-green"><b>Section Two: </b></span>Your Debt
				</p>
			</div>
			<div class="show-hide-content">
				<h1>Debt Wizard</h1>
				<h2 class="wizard-subhead">
					“How much debt do I have, and is it too much?”
				</h2>
				<p>
					This one’s a biggie, so we’ll address it by looking at two
					ways to measure debt: <b>debt burden</b> and
					<b>total debt</b>. Let’s look at them one at a time.
				</p>
				<h2>Debt Burden: How much of your paycheck goes to debt</h2>
				<p>
					If you’ve ever bought a house, you may be familiar with your
					debt-to-income ratio (DTI). It’s a number showing how much
					of your income is allotted to servicing your debt. As the
					example below shows, you can calculate it by dividing your
					monthly debt payments (that show on your credit report) by
					your monthly pre-tax (gross) income.
				</p>
				<div class="illustration-debt-burden"></div>
				<article class="inset-panel">
					<p class="eyebrow">Interactive Wizard</p>
					<h1>Calculate Your Own Debt Burden</h1>
					<p>
						The aim is to get your total debt burden (DTI) down to
						below 36% of your income, with no more than 28%
						servicing your mortgage, if you have one. Let’s find out
						where you are now.
					</p>
					<div class="worksheet debtBurden">
						<div style="padding: 8px 28px 8px 18px">
							<p>
								All monthly expenses that show on your credit
								report
								<span
									v-if="
										FixedDebtWorksheet.sum == 0 &amp;&amp;										CreditCardWorksheet.sum == 0
									"
									style="display: block"
									><a
										class="back-arrow"
										@click="
											openSectionIfClosed('#part-one', null, $event);
											openSectionIfClosed(
												'#part-one-calc',
												'step-three',
												$event
											);
										"
										>Complete Steps 3 and 4 of the Spending
										Wizard</a
									></span
								>
							</p>
							<p class="dollar-field">
								{{
									(FixedDebtWorksheet.sum +
										CreditCardWorksheet.sum)
										| currency
								}}
							</p>
						</div>
						<worksheet-sum
							v-bind:worksheet="GrossMonthlyIncomeBurden"
						></worksheet-sum>
					</div>
					<div class="inset-summary flush-bottom">
						<p>Debt Burden</p>
						<p class="lt-green">{{ debtBurden }}%</p>
						<p class="subtext">
							Monthly debt payments ÷ gross monthly income × 100
						</p>
					</div>
				</article>
				<h2>The big picture: “Total Debt”</h2>
				<p>
					If “debt burden” represents how much of your individual
					paychecks are consumed by debt payments, “total debt” takes
					a wider view. Also known as debt-to-income (DTI), it
					compares all of your unsecured debt obligations to your
					annual income. Ideally, you want your unsecured debt to be
					less than half of what you make in a year.
				</p>
				<aside class="info-box">
					<header>The two kinds of debt</header>
					<h2>Secured Debt</h2>
					<p>
						This is when you borrow money to buy a real, tangible
						asset (like a house or car). If you don’t repay that
						debt, the bank or lender can repossess that asset. This
						doesn’t count towards your “total debt.”
					</p>
					<h2>Unsecured Debt</h2>
					<p>
						This type has no collateral, meaning borrowers don’t
						have to put up or risk losing any of their personal
						property to borrow the money. Common examples include
						personal loans, student loans, credit card debt and
						medical debt. This is the kind that factors into “total
						debt.”
					</p>
				</aside>
				<p>
					The example below shows how total debt works. Total debt
					<b>over 50% is cause for concern</b>, and
					<b>more than 75% is serious trouble</b>.
				</p>
				<div class="illustration-total-debt"></div>
				<article class="inset-panel">
					<header>
						<p class="eyebrow">Interactive Wizard</p>
						<h1 id="total-debt">Calculate Your Own Total Debt</h1>
						<p>
							Remember, the goal is to have a total debt figure
							where your unsecured debt is less than 50% of your
							annual gross income. If it’s above that, we’ll help
							you troubleshoot later on.
						</p>
					</header>
					<div class="worksheet totalDebt">
						<worksheet-sum
							v-bind:worksheet="UnsecuredDebt"
						></worksheet-sum>
						<worksheet-sum
							v-bind:worksheet="GrossAnnualIncome"
						></worksheet-sum>
					</div>
					<div class="inset-summary flush-bottom">
						<p>Total Debt</p>
						<p class="lt-green">{{ totalDebt }}%</p>
						<p class="subtext">
							Unsecured debt balances ÷ annual income × 100
						</p>
					</div>
				</article>
			</div>
		</section>
		<section class="with-shelf sectionclosed" id="part-three">
			<div
				@click="toggleSection('#part-three')"
				id="part-three-btn"
				class="section-shelf show-hide-btn"
			>
				<div class="circle-arrow up xpander"></div>
				<p>
					<span class="lt-green"><b>Section Three: </b></span>Your
					Credit
				</p>
			</div>
			<div class="show-hide-content">
				<h1>Credit Score</h1>
				<h2 class="wizard-subhead">“What is my credit&nbsp;score?”</h2>
				<p>
					Your credit score is one of the most important gauges of
					your overall debt health. Lenders use it to evaluate how
					risky it might be to lend you money because it’s an
					indicator of your past credit habits and behaviors. <br />
					<br />
					These factors include the following:
				</p>
				<ul class="green-bullets">
					<li>
						<b>Payment History</b> — No/fewer late payments is
						better.
					</li>
					<li>
						<b>Age of Credit</b> — The average age of all your
						credit accounts. Older is better.
					</li>
					<li>
						<b>Utilization</b> — How much of your total credit limit
						you use. Lower is better.
					</li>
					<li>
						<b>Negative Marks</b> — Records of delinquent or
						derogatory accounts. None is better.
					</li>
					<li>
						<b>Credit Inquiries</b> — Lenders viewing your credit
						score for new applications (aka “hard pulls” or "hard
						inquiries"). Fewer recent pulls are better, although for
						some, like mortgages and student loans, multiple hard
						pulls in a 45-day period are counted as a single pull.
					</li>
					<li>
						<b>Available Credit</b> — Total amount of credit
						available (unutilized). More is better.
					</li>
				</ul>
				<p>
					<a
						href="https://my.lendingtree.com/"
						>Accessing your credit score</a
					>
					is now easier than ever and completely free. This is great
					because you can make more informed decisions to improve your
					score faster. Higher credit scores mean lower rates (aka
					lower payments), larger credit limits and greater added
					benefits (like rewards credit cards). In short, the better
					your credit, the more willing banks will be to lend you
					money or to refinance existing debt for fairer terms. <br />
					<br />
					Once you get your credit score, pay attention to its range
					so you’ll know how easy it will be for you to eliminate your
					debt. The better your score, the easier eliminating your
					debt becomes.
				</p>
				<article class="inset-panel">
					<header>
						<p class="eyebrow">Interactive Wizard</p>
						<h1>What’s your credit&nbsp;score?</h1>
						<p>
							To make the best use of this guide and bolster your
							future financial progress, you should know your
							credit score. Select the score below that best
							represents you.<br />
							<br />
							If you don’t know your current credit score,
							<a
								href="https://my.lendingtree.com/"
								>create a free account</a
							>
							with LendingTree to get unlimited access to your
							credit score and
							<a
								href="https://my.lendingtree.com/"
								>a lot more</a
							>.
						</p>
					</header>
					<hr />
					<div class="credit-score-selector" id="credit-score">
						<div>
							<h2>Fair/Poor</h2>
							<p>
								If you have this credit score, it’s a work in
								progress. You’ll have to put in some extra
								effort, but you still have options for getting
								out of debt.
							</p>
						</div>
						<div class="credit-score-graphic-wrapper">
							<div
								class="credit-wheel"
								style="
									background-image: url(./assets/images/debt-microsite-credit-wheel-below-650.svg);
								"
							></div>
							<a
								class="green-button"
								@click="
									(creditScoreLevel = 1),
										$window.s.tl(true, 'o', 'Fair Credit')
								"
								:class="{ selected: creditScoreLevel == 1 }"
								>I have fair or poor credit</a
							>
						</div>
					</div>
					<hr />
					<div class="credit-score-selector">
						<div>
							<h2>Good</h2>
							<p>
								Good credit is a solid place to be. There are
								plenty of strategies available to you for
								reducing and paying off your debt.
							</p>
						</div>
						<div class="credit-score-graphic-wrapper">
							<div
								class="credit-wheel"
								style="
									background-image: url(./assets/images/debt-microsite-credit-wheel-above-650.svg);
								"
							></div>
							<a
								class="green-button"
								@click="
									(creditScoreLevel = 2),
										$window.s.tl(true, 'o', 'Good Credit')
								"
								:class="{ selected: creditScoreLevel == 2 }"
								>I have good credit</a
							>
						</div>
					</div>
					<hr />
					<div class="credit-score-selector">
						<div>
							<h2>Very Good / Excellent</h2>
							<p>
								If you have a score like this, you can request a
								loan with a lower interest rate to pay off your
								debt faster and more cheaply.
							</p>
						</div>
						<div class="credit-score-graphic-wrapper">
							<div
								class="credit-wheel"
								style="
									background-image: url(./assets/images/debt-microsite-credit-wheel-above-700.svg);
								"
							></div>
							<a
								class="green-button"
								@click="
									(creditScoreLevel = 3),
										$window.s.tl(
											true,
											'o',
											'Very Good Credit'
										)
								"
								:class="{ selected: creditScoreLevel == 3 }"
								>I have very good credit</a
							>
						</div>
					</div>
					<div class="inset-summary flush-bottom">
						<p>Your Credit Score</p>
						<p class="lt-green">
							<b>{{ creditLevelName[creditScoreLevel] }}</b>
						</p>
					</div>
				</article>
				<p>
					If you already have good or excellent credit, you may be
					eligible for financial solutions like
					<a
						href="https://splitter.lendingtree.com/api/split?id=catch-all-pl"
						>personal loans</a
					>
					and
					<a
						href="https://www.comparecards.com/balance-transfer"
						>balance transfer credit cards</a
					>
					to enhance your debt elimination journey. If you’re not
					eligible, or don’t want to consolidate your debt, you can
					try other
					<router-link to="/take-control-of-your-debt"
						>self-managed methods</router-link
					>
					to pay down your debt. As you pay off your debt, your credit
					could begin to improve as you reduce your total debt and
					increase the amount of your available credit (each payment
					effectively boosts your credit). <br />
					<br />
					No matter where your credit falls on the scale, you can
					track it for free with the
					<a
						href="https://my.lendingtree.com/"
						>LendingTree app</a
					>.
				</p>
			</div>
		</section>
		<section class="backdrop" id="financial-summary">
			<header>
				<p class="eyebrow">Financial Summary</p>
				<h1>Your Debt Plan</h1>
				<p>
					Check out this handy summary based on the previous wizards.
					The summary will help give you a clearer picture of your
					overall debt situation and help you find a matching solution
					in the next part of the guide.
				</p>
			</header>
			<article class="inset-panel" style="border: 1px solid #a3a3a3">
				<header>
					<p class="eyebrow">Your Debt Plan</p>
					<h1>Personalized Analysis</h1>
					<p>
						The first thing you must do before you can pay off your
						debt is understand your financial situation. If you’ve
						filled out the preceding wizards, this summary will help
						you a lot. <br />
						<br />
						It also helps to have handy a list of all your current
						balances, annual percentage rates (APR) and minimum
						payment requirements. You can use any method that will
						help you stay organized, whether that’s a spreadsheet or
						a handwritten list in a notebook. The key is to pick a
						format that will be easy to access
						<b>and update</b> over the months (and possibly years)
						you spend working on your credit health.
					</p>
				</header>
				<h2>Your Monthly Spending</h2>
				<div class="worksheet">
					<div>
						<p>
							Monthly Usable Income (income after fixed expenses)
						</p>
						<p>
							<a
								v-if="noSpendingData"
								@click="
									openSectionIfClosed('#part-one', 'part-one', $event)
								"
								>Complete for Result</a
							><strong v-else>{{
								usableIncome | currency
							}}</strong>
						</p>
					</div>
					<div>
						<p>
							Monthly Disposable Income (income left over after
							all expenses)
						</p>
						<p>
							<a
								v-if="noSpendingData"
								@click="
									openSectionIfClosed('#part-one', 'part-one', $event)
								"
								>Complete for Result</a
							><strong v-else>{{
								disposableIncome | currency
							}}</strong>
						</p>
					</div>
				</div>
				<div class="inset-bar">
					<p v-if="noSpendingData">
						Complete the
						<a @click="openSectionIfClosed('#part-one', 'part-one', $event)"
							>Spending Wizard</a
						>
						for your spending analysis.
					</p>
					<p v-else v-html="spendingMsg"></p>
				</div>
				<h2>Your Debt</h2>
				<div class="worksheet">
					<div>
						<p>
							Your Debt Burden (debt payments compared to income)
						</p>
						<p>
							<a
								v-if="noDebtData"
								@click="
									openSectionIfClosed('#part-two', 'part-two', $event)
								"
								>Complete for Result</a
							><strong v-else>{{ debtBurden }}%</strong>
						</p>
					</div>
					<div>
						<p>
							Your Total Debt (money owed compared to annual
							income)
						</p>
						<p>
							<a
								v-if="noDebtData"
								@click="
									openSectionIfClosed(
										'#part-two',
										'total-debt',
										$event
									)
								"
								>Complete for Result</a
							><strong v-else>{{ totalDebt }}%</strong>
						</p>
					</div>
				</div>
				<div class="inset-bar">
					<p v-if="noDebtData">
						Complete the
						<a @click="openSectionIfClosed('#part-two', 'part-two', $event)"
							>Debt Wizard</a
						>
						for your debt analysis.
					</p>
					<p v-else>
						<span
							v-html="debtBurdenLevelMessage[debtBurdenLevel]"
						></span>
						<span v-if="mortgageDebtHigh"
							>Your mortgage is currently an excessive debt
							burden. You may want to look into refinancing your
							home to lower your payments.
						</span>
						<span
							v-html="totalDebtLevelMessage[totalDebtLevel]"
						></span>
					</p>
				</div>
				<h2>Your Credit Score</h2>
				<div class="worksheet">
					<div>
						<p>Your Credit Score</p>
						<p>
							<a
								v-if="creditScoreLevel == 0"
								@click="
									openSectionIfClosed(
										'#part-three',
										'part-three',
										$event
									)
								"
								>Complete for Result</a
							><b v-else>{{
								creditLevelName[creditScoreLevel]
							}}</b>
						</p>
					</div>
				</div>
				<!-- DEBT PLAN RESULTS -->
				<!-- Unfinished -->
				<div
					v-if="global.solutionGroup == 0"
					class="inset-bar debt-plan-results"
				>
					<h2>
						<span class="bulbicon"></span>Your Debt Plan Results
					</h2>
					<p>
						Complete the previous wizards and we can help point you
						in the right direction.
					</p>
				</div>
				<!-- Recommendation: Expenses Troubleshooter -->
				<div
					v-if="global.solutionGroup == 6"
					class="inset-bar debt-plan-results"
				>
					<h2>
						<span class="bulbicon"></span>Recommendation:
						<span class="lt-green">Expenses Troubleshooter</span>
					</h2>
					<p>
						You’ve indicated you spend more than you earn. We
						strongly recommend you visit our
						<router-link to="/expenses-troubleshooter"
							>Expenses Troubleshooter</router-link
						>. Until you spend less than you earn, paying off your
						loans sooner will not be possible.
					</p>
					<div class="cta-block">
						<router-link
							class="green-button cta-button"
							to="/expenses-troubleshooter"
							>Go to the Expenses Troubleshooter</router-link
						>
					</div>
				</div>
				<!-- Recommendation: Negotiate Your Debt -->
				<div
					v-if="global.solutionGroup == 4"
					class="inset-bar debt-plan-results"
				>
					<h2>
						<span class="bulbicon"></span>Recommendation:
						<span class="lt-green">Negotiate Your Debt</span>
					</h2>
					<p>
						Your answers indicate you have very high total unsecured
						debt. Typically, debt this high can only be cleared by
						bankruptcy, or possibly through debt negotiation. We
						recommend you learn about all the options available to
						make a decision right for you.
					</p>
					<div class="cta-block">
						<router-link
							class="green-button cta-button"
							to="/bankruptcy"
							>Learn about Bankruptcy</router-link
						>
						<router-link
							class="green-button cta-button"
							to="/negotiating-debt"
							>Learn about Debt Negotiation</router-link
						>
					</div>
				</div>
				<!-- Recommendation: Personal Loans -->
				<div
					v-if="global.solutionGroup == 1"
					class="inset-bar debt-plan-results"
				>
					<h2>
						<span class="bulbicon"></span>Recommendation:
						<span class="lt-green">Consolidate Your Debt</span>
					</h2>
					<p>
						Based on your results, you’re most likely to be eligible
						for a personal loan, which can clear your credit cards
						and consolidate them into a repayment plan with a fixed
						payment. The amount of money saved on interest can be
						impressive. You should also read about balance transfer
						cards as an opportunity if you increase your credit
						score.
					</p>
					<div class="cta-block">
						<router-link
							class="green-button cta-button"
							to="/debt-consolidation"
							>Go to Your Solutions</router-link
						>
					</div>
				</div>
				<!-- Recommendation: Balance Transfers -->
				<div
					v-if="global.solutionGroup == 2"
					class="inset-bar debt-plan-results"
				>
					<h2>
						<span class="bulbicon"></span>Recommendation:
						<span class="lt-green">Balance Transfers</span>
					</h2>
					<p>
						Based on your results, you’re likely eligible for both
						personal loans and balance transfer credit cards.
						Balance transfers typically offer the lowest rates.
						Between those two options, it’s often possible to
						eliminate all or most of your high-interest debt and
						start paying them down with low interest — or even no
						interest at all!
					</p>
					<div class="cta-block">
						<router-link
							class="green-button cta-button"
							to="/balance-transfer-cards"
							>Go to Your Solutions</router-link
						>
					</div>
				</div>
				<!-- Debt Question -->
				<div
					v-if="global.solutionGroup == 8"
					class="inset-bar debt-plan-results"
				>
					<h2>
						<span class="bulbicon"></span>Your Debt Plan Results
					</h2>
					<p>
						We have one more question before we provide you with a
						recommendation. Are you struggling to pay minimum debt
						payments?
					</p>
					<div class="cta-block">
						<a
							class="green-button cta-button"
							@click="poorCreditSituation = 1"
							>I can pay them</a
						>
						<a
							class="green-button cta-button"
							@click="poorCreditSituation = 2"
							>I’m struggling</a
						>
					</div>
				</div>
				<!-- Low Credit / Paying debts -->
				<div
					v-if="global.solutionGroup == 3"
					class="inset-bar debt-plan-results"
				>
					<h2>
						<span class="bulbicon"></span>Recommendation:
						<span class="lt-green">Build Your Credit</span>
					</h2>
					<p>
						It looks like the best place for you to start is working
						on techniques to increase your credit. Once you’ve
						increased your credit score, new debt-busting
						opportunities may become available, so it’s worth the
						effort.
					</p>
					<div class="cta-block">
						<a
							class="green-button cta-button"
							href="/take-control-of-your-debt"
							>Go to Take Control of Your Debt</a
						>
					</div>
				</div>
				<!-- Low Credit / Struggling -->
				<div
					v-if="global.solutionGroup == 9"
					class="inset-bar debt-plan-results"
				>
					<h2>
						<span class="bulbicon"></span>Recommendation:
						<span class="lt-green">Negotiate Your Debt</span>
					</h2>
					<p>
						When debt payments become a major strain, finding ways
						to negotiate with creditors to reduce the burden can
						make the difference between defaulting and debt freedom.
					</p>
					<div class="cta-block">
						<router-link
							class="green-button cta-button"
							to="/negotiating-debt"
							>Go to Negotiating Your Debt</router-link
						>
					</div>
				</div>
			</article>
		</section>
	</main>
</template>

<script>
import WorksheetSum from "@/components/WorksheetSum.vue";

export default {
	name: "DebtAssessment",
	components: { "worksheet-sum": WorksheetSum },
	data: function () {
		return {
			global: this.$root.$children[0].$data, // App contains our variables; it's the first child of $root

			IncomeWorksheet: {
				sum: 0,
				inputs: [
					{
						name: "Monthly Employment Income",
						amount: 0,
						default: true,
					},
				],
				slug: "income",
				additionalButton: "Additional Monthly Income",
				additionalItemPlaceholder: "Additional Monthly Income",
			},
			MonthlyExpensesWorksheet: {
				sum: 0,
				inputs: [
					{ name: "Rent", amount: 0, default: true },
					{ name: "Auto insurance", amount: 0, default: true },
					{ name: "Life insurance", amount: 0, default: true },
					{
						name:
							"Health insurance (only if NOT deducted from paycheck)",
						amount: 0,
						default: true,
					},
					{
						name: "House or renters insurance",
						amount: 0,
						default: true,
					},
					{
						name: "Average electric and gas utility bills",
						amount: 0,
						default: true,
					},
					{
						name: "Cable and internet bills",
						amount: 0,
						default: true,
					},
					{
						name: "Mobile and landline phone service",
						amount: 0,
						default: true,
					},
					{
						name: "Mobile handset payments",
						amount: 0,
						default: true,
					},
					{
						name:
							"All streaming media subscriptions (like Netflix or Spotify)",
						amount: 0,
						default: true,
					},
					{
						name: "All app subscriptions (like iCloud storage)",
						amount: 0,
						default: true,
					},
					{
						name:
							"Other subscriptions (like magazines, food boxes)",
						amount: 0,
						default: true,
					},
					{
						name: "Memberships (gym, museums, parks, wine clubs)",
						amount: 0,
						default: true,
					},
					{
						name:
							"Regular investment or savings contributions, like for an IRA or 401(k)",
						amount: 0,
						default: true,
					},
					{
						name:
							"Other activities (like piano lessons, recurring donations)",
						amount: 0,
						default: true,
					},
				],
				slug: "generalexpense",
				additionalButton:
					"Additional monthly expense (do not include credit card or personal loan payments)",
				additionalItemPlaceholder: "Additional monthly expense name",
			},
			FixedDebtWorksheet: {
				sum: 0,
				inputs: [
					{ name: "Mortgage Payment", amount: 0, default: true },
					{ name: "Auto Loan Payment", amount: 0, default: true },
					{ name: "Student Loan Payment", amount: 0, default: true },
				],
				slug: "fixeddebtpayment",
				additionalItemPlaceholder: "Monthly debt expense",
				additionalButton:
					"Additional monthly debt expense (do not include credit card or personal loan payments)",
			},
			CreditCardWorksheet: {
				sum: 0,
				inputs: [
					{ name: "Credit Card 1", amount: 0, default: false },
					{ name: "Credit Card 2", amount: 0, default: false },
					{ name: "Personal Loan", amount: 0, default: false },
				],
				slug: "creditcard",
				additionalItemPlaceholder: "Credit Card",
				additionalButton:
					"Additional credit card or personal loan minimum payment",
			},
			MonthlyVariableExpenses: {
				sum: 0,
				inputs: [
					{
						name:
							"Groceries (food and household products, including pet food)",
						amount: 0,
						default: true,
					},
					{
						name: "Eating out (including coffee runs and bars)",
						amount: 0,
						default: true,
					},
					{ name: "Clothing", amount: 0, default: true },
					{ name: "Shoes", amount: 0, default: true },
					{
						name: "Alcohol (purchased for home)",
						amount: 0,
						default: true,
					},
					{
						name: "Smoking consumables (cigarettes, vape fluid)",
						amount: 0,
						default: true,
					},
					{ name: "Gasoline", amount: 0, default: true },
					{
						name: "Auto/motorcycle/bicycle maintenance",
						amount: 0,
						default: true,
					},
					{
						name:
							"Travel accommodations (hotels, campsites, vacation rentals)",
						amount: 0,
						default: true,
					},
					{
						name:
							"Entertainment activities (tours, admission tickets)",
						amount: 0,
						default: true,
					},
					{ name: "Taxis or ridesharing", amount: 0, default: true },
					{
						name: "Public transit (bus, rail, subway)",
						amount: 0,
						default: true,
					},
					{
						name: "Gifts (birthdays and holidays)",
						amount: 0,
						default: true,
					},
					{
						name:
							"Electronics (computers, TVs, home automation, etc.)",
						amount: 0,
						default: true,
					},
					{
						name: "Appliances (toaster, dishwasher, hair dryer)",
						amount: 0,
						default: true,
					},
					{
						name: "House cleaner (if not a fixed expense)",
						amount: 0,
						default: true,
					},
					{
						name: "Haircuts and spa treatments like massages",
						amount: 0,
						default: true,
					},
					{
						name: "Other daily habits (vending machines)",
						amount: 0,
						default: true,
					},
					{
						name:
							"Gambling, including lottery tickets (DON’T count winnings as income)",
						amount: 0,
						default: true,
					},
					{
						name: "Lawn service (if not a fixed expense)",
						amount: 0,
						default: true,
					},
					{ name: "Veterinary costs", amount: 0, default: true },
					{
						name:
							"Medical expenses (co-pays, prescriptions, OTC medicine/equipment)",
						amount: 0,
						default: true,
					},
				],
				slug: "variableexpenses",
				additionalButton: "Additional variable expense",
				additionalItemPlaceholder: "Variable expense",
			},
			UnsecuredDebt: {
				sum: 0,
				inputs: [
					{
						name:
							"Total owed on unsecured debt (Full credit card balances, student loans and medical bills)",
						amount: 0,
						default: true,
					},
				],
				slug: "unsecureddebt",
			},
			GrossAnnualIncome: {
				sum: 0,
				inputs: [
					{
						name: "Your gross (pre-tax) annual income",
						amount: 0,
						default: true,
					},
				],
				slug: "grossannualincome",
			},
			GrossMonthlyIncomeBurden: {
				sum: 0,
				inputs: [
					{
						name: "Your gross (pre-tax) monthly income",
						amount: 0,
						default: true,
					},
				],
				slug: "grossmonthlyincomeburden",
			},
			creditScoreLevel: 0,
			creditLevelName: [
				"Select Above",
				"Fair/Poor",
				"Good",
				"Very Good/Excellent",
			],
			debtBurdenLevelMessage: [
				"",
				'Your debt burden is <span class="lt-green">GOOD</span>. ',
				'Your debt burden is <span class="lt-salmon">MODERATE</span>. ',
				'Your debt burden is <span class="lt-red">HIGH</span>. ',
			],
			totalDebtLevelMessage: [
				"",
				'You have <span class="lt-green">LOW</span> total debt, that’s great!',
				'Your Total Debt is <span class="lt-salmon">MODERATE</span>.',
				'Your Total Debt is <span class="lt-red">HIGH</span>.',
			],
			debtBurdenLevelName: ["", "GOOD", "MODERATE", "HIGH"],
			totalDebtLevelName: ["", "LOW", "MODERATE", "HIGH"],
			poorCreditSituation: 0,
			notMsie: !(window.navigator.userAgent.indexOf("MSIE ") > -1),
		};
	},

	computed: {
		totalDebt: function () {
			if (this.UnsecuredDebt.sum != 0 && this.GrossAnnualIncome.sum != 0)
				return Math.round(
					(this.UnsecuredDebt.sum / this.GrossAnnualIncome.sum) * 100
				);
			else return "00";
		},

		debtBurden: function () {
			if (this.GrossMonthlyIncomeBurden.sum > 0)
				return Math.round(
					((this.FixedDebtWorksheet.sum +
						this.CreditCardWorksheet.sum) /
						this.GrossMonthlyIncomeBurden.sum) *
						100
				);
			else return "00";
		},

		noDebtData: function () {
			return this.debtBurden == "00" || this.totalDebt == "00";
		},

		mortgageDebtHigh: function () {
			if (
				this.GrossMonthlyIncomeBurden.sum > 0 &&
				this.FixedDebtWorksheet.inputs[0].amount > 0 &&
				this.debtBurden < 36
			) {
				return (
					this.FixedDebtWorksheet.inputs[0].amount /
						this.GrossMonthlyIncomeBurden.sum >=
					0.29
				);
			} else return false;
		},

		disposableIncome: function () {
			return (
				this.IncomeWorksheet.sum -
				this.MonthlyExpensesWorksheet.sum -
				this.FixedDebtWorksheet.sum -
				this.CreditCardWorksheet.sum -
				this.MonthlyVariableExpenses.sum
			);
		},

		usableIncome: function () {
			return (
				this.IncomeWorksheet.sum -
				this.MonthlyExpensesWorksheet.sum -
				this.FixedDebtWorksheet.sum
			);
		},

		noSpendingData: function () {
			return this.disposableIncome == 0 && this.usableIncome == 0;
		},

		spendingMsg: function () {
			if (this.disposableIncome == 0) return "";
			if (this.disposableIncome < 50 || this.usableIncome < 0)
				return "It looks like you&rsquo;re going to need to free up some income. See our <a href='/expenses-troubleshooter'>Expenses Troubleshooter</a>.";
			return "It looks like you spend <span class='lt-green'>LESS</span> than you earn. That&rsquo;s good.";
		},

		debtBurdenLevel: function () {
			if (this.debtBurden != "00") {
				if (this.debtBurden < 29) return 1;
				if (this.debtBurden < 36) return this.mortgageDebtHigh ? 2 : 1;
				if (this.debtBurden < 43) return 2;
				return 3;
			} else return 0;
		},

		totalDebtLevel: function () {
			if (this.totalDebt != "00") {
				if (this.totalDebt > 75) return 3;
				if (this.totalDebt >= 50) return 2;
				return 1;
			} else return 0;
		},

		solutionGroupCalc: function () {
			if (this.noDebtData) return 0;
			if (this.usableIncome < 0 || this.disposableIncome < 0)
				return 6; /* Expense Troubleshooter */
			if (this.creditScoreLevel == 2) return 1; /* Personal Loans */
			if (
				this.totalDebt > 75 &&
				this.debtBurden > 43 &&
				this.creditScoreLevel == 1
			)
				return 4; /* Debt Negotiation */
			if (this.creditScoreLevel == 3) return 2; /* Balance Transfers */
			if (this.creditScoreLevel == 1 && this.poorCreditSituation == 1)
				return 3; /* Poor Credit: Build Credit */
			if (this.creditScoreLevel == 1 && this.poorCreditSituation == 2)
				return 9; /* Poor Credit: Negotiation */
			if (this.creditScoreLevel == 1) return 8; /* Low Credit */
			return 0;
		},
	},
	methods: {
		downloadCSV(event) {
			let theCSV = "LendingTree Spending Budget Worksheet";

			worksheetToCSV(
				this.IncomeWorksheet,
				"Monthly Take-home Pay",
				"Total Monthly Income"
			);
			worksheetToCSV(
				this.MonthlyExpensesWorksheet,
				"Monthly Non-debt Fixed Payments",
				"Total Monthly Fixed Payments"
			);
			worksheetToCSV(
				this.FixedDebtWorksheet,
				"Monthly Fixed Debt Payments",
				"Total Monthly Fixed Payments"
			);

			theCSV += "\n\nMonthy Usable Income, " + this.usableIncome;

			worksheetToCSV(
				this.CreditCardWorksheet,
				"Credit Card Monthly Minimum Payments",
				"Total Monthly Credit Card Minimum Payments"
			);
			worksheetToCSV(
				this.MonthlyVariableExpenses,
				"Monthly Variable Expenses",
				"Total Monthly Variable Expenses"
			);

			theCSV += "\n\nMonthy Usable Income, " + this.disposableIncome;

			event.target.href =
				"data:text/csv;charset=UTF-8," + encodeURIComponent(theCSV);
			return true;

			function worksheetToCSV(worksheet, title, totalLabel) {
				theCSV += "\n\n" + title;
				worksheet.inputs.forEach(function (item) {
					theCSV +=
						item.amount > 0
							? "\n" + item.name.replace(/"/g, '""') +
							"," + item.amount
							: "";
				});
				theCSV +=
					"\n\n" +
					totalLabel.replace(/"/g, '""') +
					"," +
					worksheet.sum;
			}
		},

		toggleSection(section) {
			document.querySelector(section).classList.toggle("sectionclosed");
			window.s.tl(true, "o", section);
		},

		openSectionIfClosed(section, anchor, e) {
			if (
				document
					.querySelector(section)
					.classList.contains("sectionclosed")
			) {
				document
					.querySelector(section)
					.classList.toggle("sectionclosed");
			}

			if (anchor) {
				document.getElementById(anchor).scrollIntoView({behavior: 'smooth'});
				e.preventDefault();
			}
		},

		gotoAnchor(anchorId,e) {
				e.preventDefault();
				document.getElementById(anchorId).scrollIntoView({behavior: 'smooth'});
		}
	},

	filters: {
		currency: function (value) {
			if (value == "" || isNaN(value)) return "$0";
			return value.toLocaleString("us-EN", {
				style: "currency",
				currency: "USD",
				maximumFractionDigits: 0,
				minimumFractionDigits: 0,
			});
		},
	},

	watch: {
		solutionGroupCalc: function (newVal) {
			this.global.solutionGroup = newVal;
			if (newVal > 0)
				this.global.solutionsBannerReviewText = `You have ${
					this.creditLevelName[this.creditScoreLevel]
				} credit,
			${this.debtBurdenLevelName[this.debtBurdenLevel]} debt burden, and
			${this.totalDebtLevelName[this.totalDebtLevel]} total debt.`;
		},
	},
};
</script>

<style>
.worksheet-grid label,
.worksheet-grid input {
	font-family: Lato, sans-serif;
	font-size: 17px;
	line-height: 1.3;
	color: #454545;
}
.worksheet-grid {
	margin: 36px auto;
	max-width: 768px;
}
.worksheet-grid > div {
	position: relative;
	border-top: 1px solid #cccccc;
	padding: 8px 28px 8px 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
@media (max-width: 481px) {
	.worksheet-grid > div {
		display: block;
	}
	.worksheet-grid > div > * {
		flex: 1 1 100%;
		margin: 6px 0;
	}
	.worksheet-grid > div > .fw.di {
		left: 0 !important;
	}
}
.worksheet-grid > div:last-child {
	border-bottom: 1px solid #cccccc;
}
.worksheet-grid > div:nth-child(even) {
	background: #f5f5f5;
}
.worksheet-grid .fw {
	position: relative;
	box-sizing: border-box;
}
.worksheet-grid > div > .fw.di {
	position: relative;
	left: -6px;
}
.worksheet-grid .df {
	flex: 0 0 100px;
}
.worksheet-grid > div > div.df:before {
	content: "$";
	display: block;
	position: absolute;
	z-index: 100;
	font-family: Lato, sans-serif;
	font-size: 17px;
	left: 7px;
	top: 4px;
	font-weight: bold;
}
.worksheet-grid > div > *:first-child {
	flex: 1 1 100%;
}
.worksheet-grid .fw input {
	width: 100%;
	box-sizing: border-box;
	height: 34px;
	padding: 8px;
	border: 1px solid #cccccc;
	border-radius: 4px;
	background-color: white;
	transition: border-color 0.3s, background-color 0.3s;
}
.worksheet-grid .fw.df input {
	padding: 8px 8px 8px 20px;
}
.worksheet-grid .fw input:focus {
	background-color: #eaf3f7;
	border-color: #4cb7e2;
	resize: none;
	outline: none;
}

.worksheet-grid input[type="number"]::-webkit-inner-spin-button,
.worksheet-grid input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

.worksheet-grid input[type="number"] {
	-moz-appearance: textfield;
}

.worksheet-grid label {
	margin-right: 12px;
}

@media (max-width: 767px) {
	.worksheet-grid label {
		font-size: 15px;
	}
}
.worksheet-grid button.text-button {
	width: 100% !important;
	text-align: left;
	background: none;
	border: none;
	padding: 4px 8px;
	font-family: Lato, sans-serif;
	font-size: 15px;
	color: #00aeef;
	user-select: none;
	transition: filter 0.5s;
	cursor: pointer;
}
.worksheet-grid button.text-button:hover {
	filter: brightness(0.7) saturate(1.5);
}

.cancel-icon:hover {
	background-color: red;
}

.debt-plan-results {
	padding: 40px 8px;
	margin-bottom: 0;
}

.debt-plan-results h2 {
	color: white;
	margin-top: 0;
}

.cta-block {
	margin-top: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cta-button {
	height: 50px;
	line-height: 50px;
	margin: 12px;
}

@media (max-width: 481px) {
	/* stack CTAs on mobile */
	.cta-block {
		flex-direction: column;
	}
	.cta-button {
		width: 90%;
	}
}
.green-button.selected {
	background: #101f30;
}

section.sectionclosed {
	padding-top: 0;
	padding: 48px 0 20px 0;
}
section.sectionclosed:after {
	height: 0;
}
section.sectionclosed .circle-arrow.up {
	transform: rotate(0deg);
}
section.sectionclosed .show-hide-content {
	display: none;
}
/*  */

.show-hide-calc-btn {
	position: relative;
	left: 0;
	right: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 25px;
	z-index: 10;
}
.show-hide-calc-btn .circle-arrow {
	margin: 12px 20px 12px 0;
}
.show-hide-calc-btn p {
	margin: 0;
}
.calc-toggle-sec.sectionclosed .circle-arrow.up {
	transform: rotate(0deg);
}
.calc-toggle-sec.sectionclosed .show-hide-content {
	display: none;
}
.calc-toggle-sec.sectionclosed .show-hide-calc-btn {
	margin-bottom: 25px;
}
.calc-toggle-sec.section-close {
	padding-bottom: 95px;
}
.show-hide-content.calc-content h2 {
	margin-top: 35px;
}
.calc-toggle-sec .show-hide-calc-btn:before {
	content: "";
	display: block;
	position: absolute;
	left: 50%;
	width: 100vw;
	transform: translateX(-50%);
	top: 0;
	bottom: 0;
	background: #f5f5f5;
	border-top: 1px solid #979797;
	border-bottom: 1px solid #979797;
	box-shadow: 0 3px 3px rgba(200, 200, 200, 0.3);
	z-index: -1;
	transition: background-color 0.5s;
}
.calc-toggle-sec .show-hide-calc-btn:hover:before {
	background: #cfe8ff;
}

[data-currentpage="debt-assessment"] .inset-bar:after {
	content: "";
	position: absolute;
	background: #101f30;
	left: 50%;
	width: 100vw;
	transform: translateX(-50%);
	top: 0;
	bottom: 0;
	z-index: -1;
}

a {
	/* Doesn't happen if there's no href. */
	cursor: pointer;
}
</style>