<template>
	<div id="app">
		<the-header />
		<the-solutions-banner />
		<transition name="fade">
			<keep-alive>
				<router-view></router-view>
			</keep-alive>
		</transition>
		<the-footer />
		<the-navigation />
	</div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";
import TheNavigation from "@/components/TheNavigation";
import TheSolutionsBanner from "@/components/TheSolutionsBanner";

export default {
	components: {
		TheHeader,
		TheFooter,
		TheNavigation,
		TheSolutionsBanner,
	},
	/* GLOBALS */
	data: function () {
		return {
			solutionsBannerReviewText: "",
			solutionGroup: 0,
			recommendedSolutions: [
				/* 0 */ [],
				/* 1 */ ["debt-consolidation"],
				/* 2 */ [
					"debt-consolidation",
					"balance-transfer-cards",
					"take-control-of-your-debt",
				],
				/* 3 */ ["take-control-of-your-debt"],
				/* 4 */ [
					"negotiating-debt",
					"bankruptcy",
					"take-control-of-your-debt",
				],
				/* 5 */ [],
				/* 6 */ [
					"expenses-troubleshooter",
					"take-control-of-your-debt",
				],
				/* 7 */ [],
				/* 8 */ [],
				/* 9 */ ["negotiating-debt", "bankruptcy"],
			],
		};
	},

	mounted() {
		// Append incoming queryparams to any outgoing links
		// Note that this is handled differently in Overview for external links that are not <a> tags
		
		document.addEventListener("click", function (e) {
			if (e.target.tagName == "A" && e.target.href && window.incomingQueryParams) {
				const rawHref = e.target.getAttribute("href");
				if (!rawHref.startsWith("/") && !rawHref.startsWith("#")) // Only append if not a hash or a relative reference to a page within the debt guide
					e.target.href =
						e.target.href +
						(e.target.href.indexOf("?") > -1 ? "&" : "?") +
						window.incomingQueryParams;
			}
		});
	},
};
</script>

<style>
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
