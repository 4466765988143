export default  {
	"start": {
		"background" : false,
		"stepClasslist" : "step-start stepIconButtons",
		"stepIconClass": "",
		"headline": "",
		"body": "Where would you like to begin?",
		"buttons": [
			{
				"buttonClasslist" : "",
				"buttonIconClass" : "icon-circle-yes",
				"buttonText" : "I’d like a recommendation",
				"analyticsID" : "",
				"clickActionDestination" : "#credit-score-query"
			},
			{
				"buttonClasslist" : "",
				"buttonIconClass" : "icon-checklist",
				"buttonText" : "Explore the guide",
				"analyticsID" : "",
				"clickActionDestination" : "#toc"
			}
		]
	},
	"credit-score-query": {
		"background" : true,
		"stepClasslist" : "headlineStep stepIconButtons",
		"stepIconClass" : "",
		"headline" : "We’d Love to Help",
		"body" : "First, do you know your credit&nbsp;score?",
		"buttons" : [
			{
				"buttonClasslist" : "",
				"buttonIconClass" : "icon-circle-no",
				"buttonText" : "No",
				"analyticsID" : "",
				"clickActionDestination" : "#credit-score-not-known"
			},
			{
				"buttonClasslist" : "",
				"buttonIconClass" : "icon-circle-yes",
				"buttonText" : "Yes, I think so",
				"analyticsID" : "",
				"clickActionDestination" : "#credit-score-level"
			}
		]
	},
	"credit-score-not-known" : {
		"background" : true,
		"stepClasslist" : "endStep",
		"stepIconClass" : "chapter-icon-credit",
		"headline" : "We can help you with that.",
		"body" : "Knowing your credit score is the first step to making a debt plan. Sign up to get your free credit score, then...",
		"buttons" : [
			{
				"buttonClasslist" : "readMore",
				"buttonIconClass" : "",
				"buttonText" : "Read More...",
				"analyticsID" : "",
				"clickActionDestination" : "/take-control-of-your-debt"
			},
			{
				"buttonClasslist" : "lt-button",
				"buttonIconClass" : "",
				"buttonText" : "Get Your Free Credit Score",
				"analyticsID" : "qa-creditscore",
				"clickActionDestination" : "https://my.lendingtree.com/"
			}
		]
	},
	"credit-score-level" : {
		"background" : true,
		"stepClasslist" : "step-credit-score",
		"stepIconClass" : "",
		"headline" : "",
		"body" : "What is your credit&nbsp;score?",
		"buttons" : [
			{
				"buttonClasslist" : "green-border",
				"buttonIconClass" : "",
				"buttonText" : "<span>Very Good to Excellent</span><span>Above 720</span>",
				"analyticsID" : "",
				"clickActionDestination" : "#high-credit-debt-query"
			},
			{
				"buttonClasslist" : "blue-border",
				"buttonIconClass" : "",
				"buttonText" : "<span>Good</span><span>661 - 720</span>",
				"analyticsID" : "",
				"clickActionDestination" : "#medium-credit-debt-query"
			},
			{
				"buttonClasslist" : "red-border",
				"buttonIconClass" : "",
				"buttonText" : "<span><p>Poor to Fair</p></span><span>Below 660</span>",
				"analyticsID" : "",
				"clickActionType" : "goToStep",
				"clickActionDestination" : "#payments-status"
			}

		]
	},

	"high-credit-debt-query": {
		"background" : true,
		"stepClasslist" : "headlineStep stepIconButtons",
		"stepIconClass" : "",
		"headline" : "",
		"body" : "Do you have over $10,000 in high-interest&nbsp;debt?",
		"buttons" : [
			{
				"buttonClasslist" : "",
				"buttonIconClass" : "icon-circle-no",
				"buttonText" : "No",
				"analyticsID" : "",
				"clickActionDestination" : "#recommend-balance-transfer-cards"
			},
			{
				"buttonClasslist" : "",
				"buttonIconClass" : "icon-circle-yes",
				"buttonText" : "Yes",
				"analyticsID" : "",
				"clickActionDestination" : "#home-ownership"
			}
		]
	},

	"medium-credit-debt-query": {
		"background" : true,
		"stepClasslist" : "headlineStep stepIconButtons",
		"stepIconClass" : "",
		"headline" : "",
		"body" : "Do you have over $10,000 in high-interest&nbsp;debt?",
		"buttons" : [
			{
				"buttonClasslist" : "",
				"buttonIconClass" : "icon-circle-no",
				"buttonText" : "No",
				"analyticsID" : "",
				"clickActionDestination" : "#recommend-personal-loans"
			},
			{
				"buttonClasslist" : "",
				"buttonIconClass" : "icon-circle-yes",
				"buttonText" : "Yes",
				"analyticsID" : "",
				"clickActionDestination" : "#home-ownership"
			}
		]
	},

	"home-ownership": {
		"background" : true,
		"stepClasslist" : "headlineStep stepIconButtons",
		"stepIconClass" : "",
		"headline" : "",
		"body" : "Do you own a home?",
		"buttons" : [
			{
				"buttonClasslist" : "",
				"buttonIconClass" : "icon-circle-no",
				"buttonText" : "No",
				"analyticsID" : "",
				"clickActionDestination" : "#recommend-personal-loans"
			},
			{
				"buttonClasslist" : "",
				"buttonIconClass" : "icon-circle-yes",
				"buttonText" : "Yes",
				"analyticsID" : "",
				"clickActionDestination" : "#recommend-home-equity"
			}
		]
	},

	"payments-status": {
		"background" : true,
		"stepClasslist" : "headlineStep stepIconButtons",
		"stepIconClass" : "",
		"headline" : "Last Question",
		"body" : "Are you keeping up with minimum payments?",
		"buttons" : [
			{
				"buttonClasslist" : "",
				"buttonIconClass" : "icon-circle-no",
				"buttonText" : "No",
				"analyticsID" : "",
				"clickActionDestination" : "#recommend-debt-relief"
			},
			{
				"buttonClasslist" : "",
				"buttonIconClass" : "icon-circle-yes",
				"buttonText" : "Yes",
				"analyticsID" : "",
				"clickActionDestination" : "#recommend-debt-assessment"
			}
		]
	},

	"recommend-debt-assessment" : {
		"background" : true,
		"stepClasslist" : "endStep",
		"stepIconClass" : "chapter-icon-expenses-troubleshooter",
		"headline" : "We recommend your take our free debt&nbsp;assessment!",
		"body" : "Our interactive debt assessment tool helps you examine your finances to get a targeted solution.",
		"buttons" : [
			{
				"buttonClasslist" : "lt-button",
				"buttonIconClass" : "",
				"buttonText" : "Take Free Debt Assessment",
				"analyticsID" : "qa-assessment",
				"clickActionDestination" : "/debt-assessment"
			}
		]
	},

	"recommend-debt-relief" : {
		"background" : true,
		"stepClasslist" : "endStep",
		"stepIconClass" : "chapter-icon-negotiating-debt",
		"headline" : "We recommend checking your eligibility for debt relief.",
		"body" : "You have indicated you may be struggling to keep up with your debt. We suggest you negotiate a more sustainable repayment plan with your creditors.",
		"buttons" : [
			{
				"buttonClasslist" : "readMore",
				"buttonIconClass" : "",
				"buttonText" : "Read More...",
				"analyticsID" : "",
				"clickActionDestination" : "/negotiating-debt"
			},
			{
				"buttonClasslist" : "lt-button",
				"buttonIconClass" : "",
				"buttonText" : "Check Debt Relief Eligibility",
				"analyticsID" : "qa-dr",
				"clickActionDestination" : "https://splitter.lendingtree.com/api/split?splitterid=guru-dr"
			}
		]
	},

	"recommend-balance-transfer-cards" : {
		"background" : true,
		"stepClasslist" : "endStep",
		"stepIconClass" : "chapter-icon-balance-transfer-cards",
		"headline" : "We recommend consolidating your debt with&nbsp;a&nbsp;balance&nbsp;transfer!",
		"body" : "Utilize your excellent credit to transfer high-interest credit card balances to 0% APR cards, or look into personal loans for all types of debt consolidation.",
		"buttons" : [
			{
				"buttonClasslist" : "readMore",
				"buttonIconClass" : "",
				"buttonText" : "Read More...",
				"analyticsID" : "",
				"clickActionDestination" : "/balance-transfer-cards"
			},
			{
				"buttonClasslist" : "lt-button",
				"buttonIconClass" : "",
				"buttonText" : "Compare Cards",
				"analyticsID" : "qa-bt",
				"clickActionDestination" : "https://www.comparecards.com/balance-transfer"
			}
		]
	},

	"recommend-personal-loans" : {
		"background" : true,
		"stepClasslist" : "endStep",
		"stepIconClass" : "chapter-icon-debt-consolidation",
		"headline" : "We recommend consolidating your debt with&nbsp;a&nbsp;personal&nbsp;loan!",
		"body" : "Utilize your good credit to consolidate high interest debt like credit cards into a single, lower interest personal loan with a known payoff date.",
		"buttons" : [
			{
				"buttonClasslist" : "readMore",
				"buttonIconClass" : "",
				"buttonText" : "Read More...",
				"analyticsID" : "",
				"clickActionDestination" : "/debt-consolidation"
			},
			{
				"buttonClasslist" : "lt-button",
				"buttonIconClass" : "",
				"buttonText" : "Calculate Payment",
				"analyticsID" : "qa-pl",
				"clickActionDestination" : "https://splitter.lendingtree.com/api/split?id=catch-all-pl"
			}
		]
	},

	"recommend-home-equity" : {
		"background" : true,
		"stepClasslist" : "endStep",
		"stepIconClass" : "chapter-icon-the-power-of-home-equity",
		"headline" : "We recommend consolidating your debt with&nbsp;home&nbsp;equity!",
		"body" : "Because you own a home, your equity can be used to pay down high-interest debt with repayment terms that work for you.",
		"buttons" : [
			{
				"buttonClasslist" : "readMore",
				"buttonIconClass" : "",
				"buttonText" : "Read More...",
				"analyticsID" : "",
				"clickActionDestination" : "/the-power-of-home-equity"
			},
			{
				"buttonClasslist" : "lt-button",
				"buttonIconClass" : "",
				"buttonText" : "Calculate Home Equity Payment",
				"analyticsID" : "qa-he",
				"clickActionDestination" : "https://splitter.lendingtree.com/api/split?id=home-equity"
			}
		]
	},


}