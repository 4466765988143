<template>
	<nav class="section-nav" :class="{ 'nav-active': navActive }">
		<div class="section-container">
			<div class="cancel" @click="navActive = false"></div>
			<ul @click="navActive = false">
				<li><router-link id="nav-overview" to="/overview">Overview</router-link></li>
				<li>
					<router-link id="nav-take-control-of-your-debt" to="/take-control-of-your-debt"
						>Take Control of Your Debt</router-link
					>
				</li>
				<li>
					<router-link id="nav-debt-consolidation" to="/debt-consolidation"
						>Debt Consolidation</router-link
					>
				</li>
				<li>
					<router-link id="nav-balance-transfer-cards" to="/balance-transfer-cards"
						>Balance Transfer Cards</router-link
					>
				</li>
				<li>
					<router-link id="nav-the-power-of-home-equity" to="/the-power-of-home-equity"
						>The Power of Home Equity</router-link
					>
				</li>
				<li>
					<router-link id="nav-negotiating-debt" to="/negotiating-debt"
						>The Art of Negotiating Debt</router-link
					>
				</li>
				<li><router-link id="nav-bankruptcy" to="/bankruptcy" class="bottom-line">Bankruptcy</router-link></li>
				<li>
					<router-link id="nav-debt-assessment" to="/debt-assessment"
						><span class="lt-green">Free</span> Debt Assessment</router-link
					>
				</li>
				<li>
					<router-link id="nav-expenses-troubleshooter" to="/expenses-troubleshooter"
						>Expenses Troubleshooter</router-link
					>
				</li>
			</ul>
		</div>

		<div class="persistent-bar" @click="navActive = !navActive">
			<div class="book-green"></div>
			<p class="persistent-bar-title">Guide Quicklinks</p>
		</div>
	</nav>
</template>

<script>
export default {
	name: "TheNavigation",
	data() {
		return {
			navActive: false,
		};
	},
	watch: {
		navActive(newVal) {
			if (newVal) {
				document.body.style.overflow = "hidden";
			} else
			{
				document.body.style.overflow = "scroll";
			}
		}
	}
};
</script>


<style scoped>
nav.section-nav {
	position: fixed;
	z-index: 1000;
	left: 0;
	right: 0;
	bottom: 0;
	max-height: 0;
	cursor: pointer;
	margin: 0;
	border-top: 1px solid #ccc;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(37,66,94);
	background: linear-gradient(180deg, rgba(37,66,94,0.9) 0%, #101f30 100%);
	transition: max-height .25s;
}

nav.section-nav .book-green {
	width: 31px;
	height: 31px;
	margin: 0 12px 0 0;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMC40IDIzLjgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMwLjQgMjMuODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiMwOEMxNzc7fQo8L3N0eWxlPgo8Zz4KCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yNS41LDEuNmMxLjEsMC4yLDIuMiwwLjUsMy40LDF2MTguM2MtMi0wLjctMy44LTEuMS01LjktMS4xYy0zLjIsMC01LjQsMS4xLTcuMiwyLjJWMy41CgkJYzEuMS0wLjUsMi4yLTEsMy40LTEuNHY2LjFjMCwwLjIsMC4xLDAuNSwwLjMsMC42YzAuMiwwLjEsMC41LDAuMSwwLjcsMGwyLjEtMS4ybDIuMSwxLjJjMC4yLDAuMSwwLjUsMC4xLDAuNywwCgkJYzAuMi0wLjEsMC4zLTAuMywwLjMtMC42TDI1LjUsMS42QzI1LjUsMS42LDI1LjUsMS42LDI1LjUsMS42eiBNMjMuMSwxLjRjMC40LDAsMC43LDAsMSwwVjdsLTEuNC0wLjhjLTAuMi0wLjEtMC41LTAuMS0wLjcsMAoJCUwyMC43LDdWMS43QzIxLjUsMS41LDIyLjMsMS40LDIzLjEsMS40eiBNNy4zLDBDNC45LDAsMi44LDAuNSwwLjUsMS40QzAuMiwxLjUsMCwxLjgsMCwydjE5LjVjMCwwLjIsMC4xLDAuNCwwLjIsMC41CgkJYzAuMSwwLjEsMC4zLDAuMiwwLjUsMC4yYzAuMiwwLDAuNC0wLjEsMC41LTAuMmMwLjEtMC4xLDAuMi0wLjMsMC4yLTAuNVYyLjVjMi0wLjgsMy44LTEuMiw1LjktMS4yYzIuNiwwLDUuMSwxLjEsNy4yLDIuMXYxOC41CgkJYy0xLjktMS4xLTQuMS0yLjItNy4yLTIuMmMtMi40LDAtNC40LDAuNS02LjgsMS40Yy0wLjIsMC4xLTAuMywwLjItMC40LDAuM0MwLDIxLjYsMCwyMS44LDAsMjJzMC4yLDAuMywwLjQsMC40CgkJYzAuMiwwLjEsMC40LDAuMSwwLjUsMGMyLjQtMC45LDQuMS0xLjMsNi4zLTEuM2MzLjQsMCw1LjQsMS40LDcuNiwyLjZjMC4yLDAuMSwwLjUsMC4xLDAuNywwYzIuMi0xLjMsNC4yLTIuNiw3LjYtMi42CgkJYzIuMiwwLDMuOSwwLjUsNi4zLDEuM2MwLjIsMC4xLDAuNCwwLDAuNi0wLjFjMC4yLTAuMSwwLjMtMC4zLDAuMy0wLjZWMmMwLTAuMy0wLjItMC41LTAuNC0wLjZDMjcuNiwwLjUsMjUuNSwwLDIzLjEsMAoJCWMtMywwLTUuNywxLjItNy45LDIuM0MxMywxLjIsMTAuMywwLDcuMywweiIvPgo8L2c+Cjwvc3ZnPgo=");
}


nav.section-nav .persistent-bar {
	position: fixed;
	left: 0;
	right: 0;
	height: 50px;
	bottom: 0;
	background-color: #101f30;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	z-index: 10000;
	transition: background-color .5s;
}

nav.section-nav .persistent-bar-title {
	font-family: Lato, sans-serif;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 15px;
	letter-spacing: 1px;
	margin: 0;
	color: white;
}

nav.section-nav .persistent-bar:hover {
	background-color: #306F95;
}

.section-container .cancel {
	position: absolute;
	z-index: 9999;
	width: 18px;
	height: 18px;
	right: 12px;
	top: -36px;
	z-index: 10000;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxOC43IDE4LjciIHZpZXdCb3g9IjAgMCAxOC43IDE4LjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDhjMTc3IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiPjxwYXRoIGQ9Im0uNC40IDE4IDE4Ii8+PHBhdGggZD0ibTE4LjQuNC0xOCAxOCIvPjwvZz48L3N2Zz4=);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	cursor: pointer;
}

.section-container {
	padding-top: 18px;
	width: 100%;
	max-width: 330px;
	position: relative;
	padding: 0 0;
	opacity: 0;
	transition: opacity 1s;
}

nav.section-nav.nav-active {
	max-height: 100%;
	height: 100%;
	opacity: 1;
}

.nav-active .section-container {
	opacity: 1;
}

.section-container ul {
	display: block;
	list-style: none;
	list-style-position: inside;
	margin: 0;
	padding: 0;
	text-align: center;
}

.section-container a {
	position: relative;
	font-family: Lato, sans-serif;
	font-weight: bold;
	color: white;
	font-size: 15px;
	text-transform: uppercase;
	letter-spacing: 1px;
	background-color: transparent;
	display: block;
	margin: 18px 0;
	padding: 4px 12px;
	user-select: none;
	cursor: pointer;
	text-align: left;
	transition: color .5s, background-color .5s;
	border-radius: 1px;
}

.section-container a:hover {
	background-color: #01a262;
}

.section-container a:hover .lt-green {
	color: white !important;
}


.section-container p {
	font-family: Lato, sans-serif;
	font-weight: bold;
	color: white;
	font-size: 15px;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin: 0;
}

.section-container a.router-link-exact-active {
	background-color: rgba(255,255,255,.1);
	pointer-events: none;
}

.section-container a.router-link-exact-active:hover {
	background-color: transparent;
}

.section-container a.bottom-line {
	margin-bottom: 24px;
}

.section-container a.bottom-line:after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: -12px;
	height: 1px;
	background: rgba(255,255,255,.5);
}

.book-header {
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: center center;
}

</style>